<template>
  <div class="bac">
    <el-form ref="dialogForm" :model="subForm"  label-width="1rem">
      <el-form-item label="上传封面" prop="title">
        <img :src="subForm.avatar" alt="" v-show="subForm.avatar !== ''" style="width: 1.56rem;">
        <el-upload ref="upload"
                   action="#"
                   :auto-upload="false"
                   accept="image/png,image/gif,image/jpg,image/jpeg"
                   list-type="picture-card"
                   :show-file-list="false"
                   :on-change="imgChange"
                   v-if="subForm.avatar === ''">
          <i class="el-icon-plus"></i>
          <template #tip>
            <div class="el-upload__tip" style="color: red">
              推荐上传一张156*156分辨率的图片
            </div>
          </template>
        </el-upload>
      </el-form-item>


      <el-form-item label="公司名称" prop="title">
        <el-input v-model="subForm.name" />
      </el-form-item>

      <el-form-item label="标签" prop="departmentId" placeholder="标签" style="margin-bottom: .5rem">
        <el-cascader :options="options" clearable v-model="subForm.tag" placeholder="公司标签"/>
      </el-form-item>

      <el-form-item label="规模" prop="description">
        <el-input
            v-model="subForm.size"
            placeholder="公司人数"/>
      </el-form-item>

      <el-form-item label="注册资金" prop="description">
        <el-input
            v-model="subForm.bankroll"
            placeholder="（万元）"/>
      </el-form-item>

      <el-form-item label="公司官网" prop="description">
        <el-input
            v-model="subForm.website"
            placeholder="https://XXX"/>
      </el-form-item>

      <el-form-item label="简介" prop="description">
        <el-input
            v-model="subForm.basicInf"
            :autosize="{ minRows: 2, maxRows: 4 }"
            type="textarea"
            placeholder="公司简介"/>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submit">提交</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>
<script>
let formData = new FormData();
export default {
  name:"AddSupply",
  data() {
    return {
      isEditMode: false,
      url:'',
      supplyInfo:{
        id:'',
      },

      options:[
        {
          value: '结构设计',
          label: '结构设计',
        },{
          value: '外观设计',
          label: '外观设计',
        },{
          value: '软硬件开发',
          label: '软硬件开发',
        },{
          value: '手板制造',
          label: '手板制造',
        },{
          value: '生产制造',
          label: '生产制造',
        },{
          value: '包装制造',
          label: '包装制造',
        }],
      imageUrl:'',

      subForm:{
          avatar: '',
          name: '',
          tag: '',
          inf:'',
          size:'',
          bankroll:'',
          website: '',
          basicInf:'',

      },
    }
  },
  mounted() {
    if (this.$route.query.companyId !== undefined){
      this.isEditMode = true;
      this.getInf();
    }
  },
  methods: {
    getInf(){
        //使用前将其转换为JSON
      this.supplyInfo.id = this.$route.query.companyId;
      this.$http({
        method: 'post',
        url: '/supplyChain/supplyDetail/company',
        data: this.supplyInfo,
      }).then(res => {
        this.subForm = res.data;
      })
    },

    //封面
    imgChange (files, fileList) {
      if (!(fileList[0].raw.type === 'image/png' || fileList[0].raw.type === 'image/gif' || fileList[0].raw.type === 'image/jpg' || fileList[0].raw.type === 'image/jpeg')) {
        this.$notify.warning({
          title: '警告',
          message: '请上传格式为image/png, image/gif, image/jpg, image/jpeg的图片'
        })
        return;
      }
      let size = fileList[0].raw.size / 1024 / 1024 / 2
      if (size > 2) {
        this.$notify.warning({
          title: '警告',
          message: '图片大小必须小于2M'
        })
        return;
      }

      formData.append('file',fileList[0].raw)

      this.$http({
        method: 'post',
        url: '/admin/uploadSupplierPic',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        this.subForm.avatar = res.data;
      })
    },

    submit() {
      this.subForm.bankroll = parseFloat(this.subForm.bankroll);
      this.subForm.size = parseInt(this.subForm.size);
      this.subForm.tag = this.subForm.tag[0];

      if (this.isEditMode){
        this.url = '/admin/updateSupplier';
      }else {
        this.url = '/admin/addSupplier';
      }

      this.$http({
        method: 'post',
        url: this.url,
        data: this.subForm,
      }).then(res => {
        if (res.data.code === 1){
          this.$notify({
            title: '上传成功',
            message: '',
            type: 'success'
          });
          this.$router.push('/AllSupply');
        }
      })
    },


  },

}
</script>

<style>
.bac{
  padding-right: .2rem;
  height: 11.94rem;
}
</style>